
#auth-view {
  background-image: url('../assets/img/gcf-background.svg') ;//'@/assets/img/gcf-background.svg';
      /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow:inset 0 0 0 2000px rgba(29, 29, 29, 0.75);
  #auth-view-image {
    svg {
      width: auto;
      height: auto;
    }
  }
}
